.aboutus .width {
  padding: 100px 150px;
  background: url("../../images/homepage/ferdinand-asakome-REptTTduJxY-unsplash\ \(1\).png")
    no-repeat right;
  background-size: contain;
  margin-top: 150px;
  margin-bottom: 150px;
}
.home.about {
  position: relative;
  background: linear-gradient(
    -20deg,
    rgba(246, 147, 20, 0.25) 0%,
    rgba(245, 175, 85, 0.25) 100%
  );
}

.aboutus p {
  font-weight: 700;
  font-size: 48px;
  line-height: 128%;
  max-width: 445px;
}

.aboutus p:nth-child(2) {
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
  margin-top: 6px;
  margin-bottom: 40px;
}

.aboutus button {
  padding: 16px 68px;
  color: #ffffff;
  background: #f69314;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 128%;
  outline: none;
  border: none;
  cursor: pointer;
}

.about .reusebash.two {
  padding-bottom: 50px;
}

@media only screen and (max-width: 1400px) {
  .aboutus .width {
    background-size: 500px;
  }
}

@media only screen and (max-width: 1100px) {
  .aboutus .width {
    background: none;
    padding: 24px;
    text-align: center;
  }

  .aboutus p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .aboutus {
    margin-top: 30px;
  }

  .aboutus p {
    font-size: 24px;
  }

  .aboutus p:nth-child(2) {
    font-size: 16px;
  }

  .home.about {
    height: calc(100vh - 130px);
    display: flex;
  }
}
