.tofro .first {
  height: calc(100vh - 100px);
}

.img-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  animation: scale 15s;
}

@keyframes scale {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.banner {
  position: absolute;
  top: 30%;
  /* left: 15%; */
  padding: 100px;
}

.banner h1 {
  font-size: 24px;
  font-weight: 300;
  color: #fff;
  line-height: 9rem;
  letter-spacing: 0.2rem;
  text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
  margin-bottom: 30px;
  opacity: 0;
  animation: moveBanner 1s 0.5s forwards;
}

.banner p {
  font-size: 18px;
  color: #fff;
  width: 70%;
  letter-spacing: 0.1rem;
  margin-bottom: 3rem;
  text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
  opacity: 0;
  animation: moveBanner 1s 0.7s forwards;
}
.banner p:before {
  content: "";
  animation: animate 60s infinite;
}

@keyframes animate {
  0% {
    content: "TO FRO Logistics was founded with a vision to provide world-class logistics solutions to businesses of all sizes, and we have been doing so for over a decade";
  }
  20% {
    content: "Our expertise in freight transportation, warehousing, and distribution has helped countless clients streamline their operations and achieve their business objectives.";
  }
  40% {
    content: "At TO FRO Logistics, we are committed to providing smart and effective Logistics Solutions for every business. With timed deliveries that offer complete flexibility";
  }
  60% {
    content: "Our experience and professional teams will facilitate the modelling and execution of projects from commencement to conclusion";
  }
  80% {
    content: "Our robust knowledge of local terain puts us in advantage position to offer you unmatched secondary distribution services";
  }
  100% {
    content: "Our Road Houlage Products offers services that ensures safe monitoring and integrity of your products until delivery";
  }
}

.banner button {
  width: 25rem;
  height: 7rem;
  background-color: #c29525;
  border: none;
  font-size: 2rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
  cursor: pointer;
  opacity: 0;
  animation: moveBanner 1s 0.9s forwards;
}

@keyframes moveBanner {
  0% {
    transform: translateY(40rem) rotateY(-20deg);
  }
  100% {
    transform: translateY(0) rotateY(0);
    opacity: 1;
  }
}

.tofro .about-us {
  width: 100%;
  background-color: #f5f5f5;
  padding-bottom: 30px;
}

.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.section-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.services {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(6, 6rem);
  grid-row-gap: 4rem;
}

.service {
  width: 100%;
  margin-bottom: 2rem;
}

.service:nth-child(1) {
  grid-column: 4 / 7;
  grid-row: 1 / 3;
}

.service:nth-child(2) {
  grid-column: 3 / 6;
  grid-row: 3 / 5;
}

.service:nth-child(3) {
  grid-column: 4 / 7;
  grid-row: 5 / -1;
}

.service:nth-child(4) {
  grid-column: 11 / 14;
  grid-row: 1 / 3;
}

.service:nth-child(5) {
  grid-column: 12 / 15;
  grid-row: 3 / 5;
}

.service:nth-child(6) {
  grid-column: 11 / 14;
  grid-row: 5 / -1;
}

.service-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #f69314;
}

.service-header i {
  font-size: 18px;
  /* color: #4b4b4b; */
  margin-right: 2rem;
}

.service-header h3 {
  font-size: 18px;
  line-height: 140%;
  font-weight: 500;
  /* margin-bottom: 2rem; */
}

.service-text {
  font-size: 16px;
  text-align: justify;
  font-weight: 500;
}

.tofro .about-us-img-wrapper {
  grid-column: 7 / 11;
  grid-row: 2 / 6;
  width: 100%;
}

.tofro .about-us-img-wrapper img {
  width: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5rem 20rem 5rem;
}

.team1 {
  padding: 0 100px;
}

.cards-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
  width: 100%;
}
.cards-wrapper2 {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 8rem;
  width: 100%;
}

.card {
  margin: 0 10px;
  width: 30%;
  height: 500px;
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  position: relative;
}

.card-img-wrapper {
  width: 100%;
  height: 100%;
  background-color: #262626;
  border-radius: 0.5rem;
}

.card-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  border-radius: 0.5rem;
  transition: opacity 0.3s;
}
.card2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.8;
  border-radius: 0.5rem;
  transition: opacity 0.3s;
}

.card:hover .card-img-wrapper img {
  opacity: 0.5;
}

.card-info {
  position: absolute;
  bottom: 0;
  padding: 2rem;
  text-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.card:hover .card-info {
  bottom: 2rem;
  opacity: 1;
  visibility: visible;
}

.card-info h2 {
  font-size: 20px;
  line-height: 140%;
  font-weight: 300;
  color: #eee;
}

.card-info h3 {
  font-size: 18px;
  font-weight: 500;
  color: #a52a2a;
  margin-bottom: 1rem;
}

.card-info p {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 300;
  color: #eee;
  width: 80%;
  margin-bottom: 2rem;
}

.card-info button {
  border-radius: 0.3rem;
  padding: 15px 40px;
  box-shadow: 0 0.1rem 0.8rem rgba(0, 0, 0, 0.4);
  font-size: 16px;
}

.contact {
  width: 100%;
  height: 100vh;
  background-color: #272727;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-wrapper {
  width: 60%;
  height: auto;
  display: flex;
  box-shadow: 0 3rem 7rem rgba(0, 0, 0, 0.5);
}

.contact-left {
  width: 35%;
  background: linear-gradient(rgba(15, 15, 15, 0.6), rgba(22, 22, 22, 0.9)),
    url(../../images/tofro/seven.jpg) center no-repeat;
  background-size: cover;
}

.contact-right {
  width: 65%;
  background-color: #eee;
  padding: 3rem 10rem 10rem 10rem;
}

.contact-heading {
  font-size: 6rem;
  font-weight: 300;
  color: #272727;
  margin-bottom: 5rem;
  text-align: center;
}

.contact-right form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  position: relative;
}

.field {
  width: 45rem;
  background-color: transparent;
  border: none;
  border-bottom: 0.2rem dashed #636363;
  margin: 3rem 0;
  padding: 1rem 1rem 1rem 0;
  font-size: 1.6rem;
  color: #4b4b4b;
}

.input-group input {
  height: 4rem;
}

.input-group textarea {
  max-height: 7rem;
  max-width: 45rem;
}

.field:focus {
  border-bottom-style: solid;
}

.input-group label {
  position: absolute;
  left: 0;
  font-size: 1.8rem;
  color: #4b4b4b;
  text-transform: uppercase;
  pointer-events: none;
  transition: all 0.3s;
}

.input-label {
  bottom: 3rem;
}

.message {
  bottom: 6rem;
}

.field:focus ~ label {
  transform: translateY(-3rem);
  font-size: 1.2rem;
}
.field:valid + label {
  transform: translateY(-3rem);
}

.submit-btn {
  width: 45rem;
  height: 5rem;
  background-color: #c29525;
  color: #fff;
  border: none;
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  cursor: pointer;
  text-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
}

@media (max-width: 1500px) {
  .tofro .about-us-img-wrapper {
    grid-row: 3 / -1;
  }

  .card {
    width: 34rem;
  }

  .contact-wrapper {
    width: 80%;
    height: auto;
  }

  .footer-content {
    width: 80%;
  }
}

@media (max-width: 1400px) {
  .banner h1 {
    font-size: 24px;
    line-height: 140%;
  }

  .banner p {
    font-size: 18px;
  }

  .banner button {
    width: 20rem;
    height: 5rem;
    font-size: 1.6rem;
  }

  .menu-link {
    font-size: 3rem;
  }

  .service:nth-child(1) {
    grid-column: 3 / 7;
  }

  .service:nth-child(2) {
    grid-column: 2 / 6;
  }

  .service:nth-child(3) {
    grid-column: 3 / 7;
  }

  .service:nth-child(4) {
    grid-column: 11 / 15;
  }

  .service:nth-child(5) {
    grid-column: 12 / 16;
  }

  .service:nth-child(6) {
    grid-column: 11 / 15;
  }
}

@media (max-width: 1300px) {
  .team {
    padding-bottom: 5rem;
  }

  .cards-wrapper {
    margin-top: 4rem;
  }

  .card {
    margin-bottom: 8rem;
  }

  .contact-wrapper {
    width: 90%;
    height: auto;
  }

  .contact-heading {
    margin-bottom: 2rem;
  }

  .field {
    margin: 2rem 0;
  }
}

@media (max-width: 1000px) {
  .banner h1 {
    font-size: 24px;
    line-height: 140%;
  }
  .card2 {
    width: 250px;
    height: 359px;
  }

  .team {
    padding: 30px;
  }

  .team1 {
    padding: 30px;
  }

  .banner p {
    font-size: 18px;
  }

  .banner button {
    width: 18rem;
    height: 4rem;
    font-size: 1.5rem;
  }

  .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }

  .service {
    width: 100%;
    margin-bottom: 30px;
  }

  .tofro .about-us-img-wrapper {
    width: 40rem;
  }

  .tofro .about-us-img-wrapper img {
    width: 100%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  }

  .contact-left {
    width: 0;
  }

  .contact-right {
    width: 100%;
  }

  .field {
    width: 55rem;
  }

  .input-group textarea {
    max-width: 55rem;
  }

  .submit-btn {
    width: 55rem;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
  }

  .copyright {
    order: 1;
    margin-top: 3rem;
  }
}

@media (max-width: 800px) {
  .cards-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .card {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .banner h1 {
    font-size: 24px;
    line-height: 140%;
  }

  .banner p {
    font-size: 18px;
    width: 100%;
  }

  .banner {
    padding: 30px;
    top: 40%;
  }

  .field {
    width: 35rem;
  }

  .input-group textarea {
    max-width: 35rem;
  }

  .submit-btn {
    width: 35rem;
  }

  .tofro .about-us-img-wrapper {
    width: 100%;
    max-width: 500px;
  }
}

@media (max-width: 500px) {
  .tofro .about {
    padding: 0 5rem !important;
  }

  .services {
    padding: 30px;
  }
}

.image {
  max-width: 300px;
}
.image img {
  width: 100%;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
}
.text {
  max-width: 500px;
  font-size: 2rem;
  font-weight: 500;
}
@media (max-width: 900px) {
  .text {
    max-width: 400px;
  }
}

@media (max-width: 750px) {
  .text {
    max-width: 400px;
    text-align: left;
  }
  .image {
    max-width: 200px;
  }
  .adeola {
    background-color: #f5f5f5;
    color: #4b4b4b;
    padding: 0 15px;
  }
  .image img {
    margin: 60px 0;
  }
}
.p {
  padding: 30px 0px;
  text-align: left;
}
.p i {
  padding-right: 10px;
}
.p p {
  padding-top: 10px;
  word-break: break-word;
}
@media (max-width: 600px) {
  .image {
    max-width: 400px;
  }
  .section-heading {
    font-size: 18px;
    margin-top: 30px;
  }
  .work h3 {
    font-size: 16px;
  }
}
@media (max-width: 450px) {
  .text {
    width: 100%;
  }
  .image {
    width: 100%;
  }
  .container2 {
    padding: 0 10px;
  }
}
@media (max-width: 350px) {
  .p {
    font-size: 15px;
    padding: 30px 0;
  }
}

.tofro .about {
  padding: 0 10rem;
}
.tofro .about p {
  font-size: 2rem;

  font-weight: 500;
}

.tofro .about li {
  font-size: 2rem;
  list-style-type: circle;

  font-weight: 500;
}
.another h1 {
  text-align: center;
  margin: 3rem 0;
  font-size: 3rem;
}
