.bodyreuse {
  background: url("../../images/service/bg.png") no-repeat left;
  background-size: 700px 100%;
}

.bodyreuse {
  padding: 80px;
}

.bodyreuse .reuse {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: 2.4rem;
  line-height: 36px;
  text-align: left;
}

.bodyreuse .reuse.reverse {
  flex-direction: row-reverse;
}

.bodyreuse .reuse p {
  margin-bottom: 40px;
  line-height: 140%;
  font-weight: 700;
  font-size: 48px;
}

.bodyreuse .reuse .text {
  max-width: 800px;
  margin-right: 40px;
  font-size: 24px;
  width: 50%;
}

.bodyreuse .reuse.reverse .text {
  margin-right: 0;
  margin-left: 20px;
}

.bodyreuse .reuse .pic {
  /* max-width: 600px;
  min-width: 450px; */
  width: 50%;
}

.bodyreuse .reuse .pic img {
  width: 100%;
}

.oilreverse {
  padding: 80px;
  background: #fff2e6;
}

.oilreverse .reuse {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: 2.4rem;
  line-height: 36px;
  text-align: left;
  margin-bottom: 200px;
}

.oilreverse .reuse.reverse {
  flex-direction: row-reverse;
}

.oilreverse .reuse p {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 60px;
}

.oilreverse .reuse .text {
  max-width: 800px;
  margin-right: 40px;
  font-size: 18px;
  width: 50%;
}

.oilreverse .reuse .text .cone {
  margin: 30px 0;
}

.oilreverse .reuse .text .cone img {
  margin: 10px;
}

.oilreverse .reuse.reverse .text {
  margin-right: 0;
  margin-left: 40px;
  width: 50%;
}

.reuse .aboutus {
  margin-top: 30px;
}

.oilreverse .reuse .pic {
  max-width: 600px;
  min-width: 450px;
  width: 50%;
}

.oilreverse .reuse .pic img {
  width: 100%;
}

.oilreverse .services {
  margin-bottom: 100px;
  padding: 80px 80px 200px;
}

.oilreverse .one {
  background: white;
}

.oilreverse .services p {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 42px;
  text-align: center;
  margin-top: 125px;
}

.oilreverse .services .hug {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.oilreverse .services .hug img {
  margin-top: 80px;
}

@media only screen and (max-width: 1500px) {
  .oilreverse .reuse .text .cone img {
    max-width: 200px;
  }
}
@media only screen and (max-width: 1300px) {
  .oilreverse .reuse .text .cone img {
    max-width: 150px;
  }
}

@media only screen and (max-width: 1000px) {
  .bodyreuse .reuse {
    flex-direction: column;
  }

  .bodyreuse .reuse.reverse {
    flex-direction: column;
  }

  .bodyreuse .reuse.reverse .text {
    margin-left: 0;
  }

  .bodyreuse .reuse .text {
    margin: 30px 0;
    width: 100%;
  }

  .bodyreuse .reuse .pic {
    min-width: auto;
    margin-top: 30px;
    width: 100%;
    max-width: 500px;
  }

  .oilreverse .reuse {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .oilreverse .reuse.reverse {
    flex-direction: column;
  }

  .oilreverse .reuse.reverse .text {
    margin-left: 0;
    width: 100%;
  }

  .oilreverse .reuse .text {
    margin: 30px 0;
    width: 100%;
  }

  .oilreverse .reuse .pic {
    min-width: auto;
    margin-top: 30px;
    width: 100%;
    max-width: 500px;
  }

  .oilreverse .services .hug img {
    width: 250px;
  }
}

@media only screen and (max-width: 800px) {
  .oilreverse .services .hug img {
    width: 150px;
  }
  .oilreverse .reuse .text .cone img {
    max-width: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .bodyreuse {
    padding: 40px;
  }

  .oilreverse {
    padding: 30px;
  }
  /* .oilreverse .reuse {
    padding: 40px;
  } */
  .oilreverse .services {
    padding: 40px 40px 100px;
  }

  .oilreverse .services .hug {
    padding: 0;
  }

  .whyanchor {
    margin-top: 0;
  }

  .bodyreuse .reuse p {
    font-size: 24px;
  }

  .bodyreuse .reuse .text {
    font-size: 16px;
    line-height: 140%;
  }

  .oilreverse .reuse .text {
    font-size: 16px;
    line-height: 120%;
  }
}

@media only screen and (max-width: 400px) {
  .oilreverse .services .hug {
    justify-content: center;
  }
  .oilreverse .services .hug img {
    width: 130px;
  }

  .oilreverse .reuse .text .cone {
    text-align: center;
  }
}
