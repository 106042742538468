.container5 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 15px;
}
.portfolio {
  padding: 50px;
}
.container5 img {
  width: 100%;
  display: block;
  transition: all 100ms ease-out;
}
.container5 img:hover {
  transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.work h3 {
  padding: 1rem 1rem;
  font-weight: 500;
}

/* gallery */
.team2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5rem 20rem 5rem;
}

.wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
}

.wrapper .card2 {
  width: 370px;
  height: 450px;
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  position: relative;
  margin-top: 5rem;
}

.wrapper .card-img-wrapper {
  width: 100%;
  height: 100%;
  background-color: #262626;
  border-radius: 0.5rem;
}

.wrapper .card-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  border-radius: 0.5rem;
  transition: opacity 0.3s;
}
.wrapper .card2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.8;
  border-radius: 0.5rem;
  transition: opacity 0.3s;
}

/* .card-info {
  position: absolute;
  bottom: 0;
  padding: 2rem;
  text-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.card:hover .card-info {
  bottom: 2rem;
  opacity: 1;
  visibility: visible;
} */

/* .card-info h2 {
  font-family: "Baloo Da 2", serif;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 300;
  color: #eee;
}

.card-info h3 {
  font-family: "Muli", serif;
  font-size: 2rem;
  font-weight: 500;
  color: #a52a2a;
  margin-bottom: 1rem;
} */

/* .card-info p {
  font-family: "Baloo da 2", serif;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 300;
  color: #eee;
  width: 80%;
  margin-bottom: 2rem;
}

.card-info button {
  width: 10rem;
  height: 3rem;
  background-color: #c29525;
  border: none;
  font-family: "Baloo Da 2", serif;
  font-size: 1.4rem;
  line-height: 1.5rem;
  color: #eee;
  border-radius: 0.3rem;
  box-shadow: 0 0.1rem 0.8rem rgba(0, 0, 0, 0.4);
} */

@media (max-width: 900px) {
  .portfolio {
    padding: 0 30px;
  }
}

@media (max-width: 310px) {
  .container5 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 15px;
  }
}
