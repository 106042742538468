.story {
  padding: 100px;
  padding-bottom: 200px;
}

.bg {
  background: #f8f8f8;
  /* background: #fff2e5; */
}

.story .width {
  display: flex;
  justify-content: space-between;
}

.story .image {
  max-width: 400px;
  margin-right: 30px;
}

.story .image img {
  width: 100%;
}

.story .talk {
  width: 50%;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.story .talk p {
  margin-bottom: 30px;
}

.story .talk p span {
  color: #f69314;
}

.story .talk p span.span {
  font-size: 32px;
  font-weight: 700;
}

.story.reverse .width {
  flex-direction: row-reverse;
}

.story.reverse .image {
  margin-right: 0;
  margin-left: 30px;
}

.story .talk .list {
  font-size: 18px;
}

.story .talk .list .two {
  display: flex;
}

.story .talk .list p {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
}

.story .talk .list p span {
  font-weight: 500;
}

.story .talk .list i {
  margin-right: 20px;
  color: #f69314;
  font-size: 24px;
}

@media only screen and (max-width: 1000px) {
  .story {
    padding: 60px;
    padding-bottom: 100px;
  }
  .story .width,
  .story.reverse .width {
    flex-direction: column-reverse;
    align-items: center;
  }
  .story.reverse .image {
    margin-left: 0;
  }

  .story .image {
    margin-right: 0;
  }

  .story .talk {
    width: 100%;
    margin-bottom: 50px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .story .talk {
    font-size: 16px;
    text-align: left;
  }

  .story .talk .list {
    font-size: 16px;
  }

  .story {
    padding: 30px;
  }

  .story .talk p span.span {
    font-size: 16px;
  }
}
