/* contact */

#contact {
  background: #f5f5f5;
  padding: 100px;
}

#contact h2 {
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
#form-container {
  width: 58%;
  background: #fff;
  padding: 3rem;
  box-sizing: border-box;
}
#form-container h3 {
  color: #000;
  /* font-family: 'Courier New', Courier, monospace; */
  margin-bottom: 2rem;
}

#form-container label {
  display: block;
  color: #000;
  margin-top: 1rem;
}

#form-container input,
#form-container textarea {
  display: block;
  border-radius: 20px;
  padding: 10px;
  background: 1px solid #ced4da;
  width: 100%;
  box-sizing: border-box;
  height: 43px;
  font-size: 1rem;
  color: #495057;
}

#form-container textarea {
  height: 170px;
  margin-bottom: 2rem;
  resize: none;
}

#address-container {
  width: 35%;
  background: #fff;
  padding: 1.5rem;
  box-sizing: border-box;
  height: 300px;
}

#address-container label {
  font-weight: 700;
  display: block;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

#address-container label:first-child {
  margin-top: 0;
}

#address-container address {
  margin-bottom: 1.5rem;
  font-style: normal;
}

#address-container a {
  text-decoration: none;
  color: #f69314;
}

#address-container a:hover {
  text-decoration: underline;
}

.flexer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #contact {
    padding: 100px 50px;
  }

  .flexer {
    flex-direction: column-reverse;
  }

  #form-container,
  #address-container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #contact {
    padding: 30px;
  }

  #form-container {
    padding: 1.5rem;
  }

  #form-container label {
    margin-bottom: 1rem;
  }

  #address-container a {
    word-wrap: break-word;
  }
}
