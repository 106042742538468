.header {
  /* height: 96px; */
  background: rgba(255, 255, 255, 0.14);
  box-shadow: rgba(255, 255, 255, 0.14);
  padding: 0 80px;
  /* color: #ffffff; */
}

a {
  text-decoration: none;
  color: inherit;
}

.header .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
}

.header .content .logo img {
  max-width: 166px;
}

.header .nav {
  display: flex;
  align-items: center;
}

.header .nav ul {
  list-style: none;
  display: flex;
}

.header .nav ul li {
  padding: 0 32px;
  cursor: pointer;
  font-weight: 700;
}

.header .button {
  border: 1px solid #c6c6c6;
  background-color: #f69314;
  color: white;
  padding: 13px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}

.header .menu {
  display: none;
}

.header .mobile {
  display: none;
}

.header .active {
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  min-height: 100vh;
  z-index: 99;
  overflow: scroll;
  max-height: 100%;
}

.header .active .three {
  background: #f69314;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 60px; */
  padding: 24px;
  /* background: #002766; */
}

.header .active .three .logo {
  max-width: 150px;
}

.header .active .three .logo img {
  width: 100%;
}

.header .products {
  padding: 10px 0;

  font-size: 16px;
  line-height: 152%;
  display: flex;
  align-items: center;
  margin: 0 20px;
  color: #121212;
  border-bottom: 0.3px solid #c6c6c6;
}

.header .noncon {
  height: 0;
  overflow: hidden;
}

.header .content {
  /* padding-top: 10px;
  margin: 0 20px; */
}

.header .content .for {
  display: flex;
  margin-bottom: 22px;
}
.header .content .for a {
  display: flex;
  align-items: center;
}

.header .content .for .image {
  width: 46px;
  height: 46px;
  margin-right: 10px;
}

.header .content .for .image img {
  width: 100%;
}

.header .nav ul li {
  position: relative;
}

.header .libus {
  position: absolute;
  width: 200px;
  height: 0;
  border: none;
  overflow: hidden;
}

.header .nav ul li:hover {
  color: #121212;
  transition: all 0.5s;
}

.header .nav ul li:hover .libus {
  position: absolute;
  height: auto;
  z-index: 1;
  width: 200px;
  /* margin-top: 30px; */
}

.header .button:hover,
.aboutus button:hover {
  background: white;
  border: #f69314 1px solid;
  color: #f69314;
  transition: all 0.5s;
}

.libus .content {
  background: white;
  margin-top: 30px;
  padding: 20px;
  padding-top: 0;
  color: #121212;
  flex-direction: column;
  border-radius: 5px;
  align-items: flex-start;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.libus .content p {
  margin-top: 30px;
}

@media only screen and (max-width: 1200px) {
  .header .nav ul li {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .header {
    padding: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    /* padding: 24px; */
    /* background: #ff8c24; */
  }

  .header .button,
  .header .nav {
    display: none;
  }

  .header .menu {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .header .content .logo {
    width: 150px;
  }
  .header .content .logo img {
    width: 100%;
  }
}
