.width {
  max-width: 1440px;
  margin: auto;
}

/* div {
  border: 2px solid black;
} */

ul li {
  list-style: none;
}
