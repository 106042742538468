.bodycontent {
  background: url("../../images/homepage/AAR-02\ 1\ \(1\)\ \(1\).png") center
    no-repeat;
  background-size: cover;
}

.bodycontent .width {
  height: calc(100vh - 90px);
}

.bodycontent .width .talk {
  width: 700px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.bodycontent .width .talk .t {
  margin-bottom: 60px;
  color: white;
  margin-left: 100px;

  font-weight: 700;
  font-size: 48px;
  line-height: 112px;
}

.bodycontent .width .talk .t p:last-child {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

@media only screen and (max-width: 800px) {
  .bodycontent .width .talk {
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
  }

  .bodycontent .width .talk .t {
    margin-left: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .bodycontent .width .talk .t {
    line-height: normal;
    font-size: 24px;
  }

  .bodycontent .width .talk .t p:last-child {
    margin-top: 20px;
    font-size: 18px;
    line-height: 140%;
  }
}
