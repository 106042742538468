.banking {
  /* background: #fff2e6; */
  /* margin-top: ; */
  /* padding: 0 100px; */
}

.banking .width {
  display: flex;
  justify-content: space-between;
  /* background: #fff2e6; */
  padding-top: 70px;
  padding-bottom: 200px;
}

.banking .width .content.reusecontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 100px;
}

.banking .width .content.reusecontent p.dev {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #f4811e;
  margin-bottom: 30px;
}

.banking .width .content.reusecontent p:nth-child(2) {
  font-weight: 700;
  font-size: 48px;
  color: black;
  line-height: 120%;
  /* width: 600px; */
  margin-bottom: 25px;
}
.banking .width .content.reusecontent p:nth-child(3) {
  font-size: 21px;
  color: black;
  line-height: 140%;
  margin-bottom: 30px;
  width: 544px;
}

.banking .width .content.second {
  max-width: 513px;
  display: flex;
  align-items: center;
}
.banking .width .content.second img {
  width: 100%;
}

.haste {
  /* height: 250px; */
  margin-top: 50px;
  margin-bottom: 30px;
  color: black;
  max-width: 422px;

  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 120%;
}

.haste .acc .first {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.haste .acc .first img {
  filter: invert(1);
}

.haste .acc .second {
  height: 0;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  margin-top: 25px;
  /* padding-bottom: 15px; */
  border-bottom: 1px solid black;

  transition: height 0.5s ease-in-out, border-bottom 0.5s ease-in-out;
}

.ag {
  height: 70px !important;
  border-bottom: 1px solid #f69a4b !important;
}

.haste .acc:nth-child(2) {
  margin-top: 45px;
}

.roll {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}

@media only screen and (max-width: 1300px) {
  .banking .width .content.reusecontent p:nth-child(2) {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1250px) {
  .banking .width .content.reusecontent {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  .banking {
    /* margin-top: 50px; */
  }
  .banking .width {
    display: block;
    padding-top: 20px;
    padding-bottom: 70px;
  }
  .banking .width .content.second {
    margin-top: 60px;
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .banking .width .content.reusecontent p.dev {
    font-size: 14px;
  }
  .banking .width .content.reusecontent p:nth-child(2) {
    font-size: 28px;
    /* width: 301px; */
  }
  .banking .width .content.reusecontent p:nth-child(3) {
    font-size: 18px;
    line-height: 140%;
    width: 400px;
  }
}

@media only screen and (max-width: 420px) {
  .banking .width .content.reusecontent p:nth-child(2) {
    font-size: 28px;
    width: 100%;
  }
  .banking .width .content.reusecontent p:nth-child(3) {
    font-size: 18px;
    line-height: 140%;
    width: 100%;
  }

  .ag {
    height: 90px !important;
    border-bottom: 1px solid #f69a4b !important;
  }
}
