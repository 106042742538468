/* the best */
.flex.container {
  display: flex;
  justify-content: space-around;
  padding: 0 100px;
}

#the-best {
  margin: 100px 0;
}
#the-best h2,
#the-best button {
  text-align: left;
}

#the-best .flex > img {
  width: 47%;
  height: 400px;
}

#the-best .flex > div {
  width: 40%;
}

#the-best .flex > div p {
  font-size: 18px;
  font-weight: 400;
  line-height: 128%;
}

#the-best ul {
  padding-left: 20px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

#the-best ul li {
  margin: 10px 0;
  list-style: initial;
}

#the-best h2 {
  margin-bottom: 20px;
  font-size: 32px;
}

@media only screen and (max-width: 1100px) {
  .flex.container {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 800px) {
  .flex.container {
    flex-direction: column;
    padding: 24px;
  }

  #the-best .flex > img,
  #the-best .flex > div {
    width: 100%;
  }

  #the-best .flex > img {
    margin-bottom: 100px;
    height: auto;
  }
}
