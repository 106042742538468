.reusebody .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 140px 50px;
  text-align: center;
}

.reusebody .content p {
  font-style: normal;
  font-weight: 700;
  font-size: 62.585px;
  line-height: 112%;
  letter-spacing: -0.02em;
  /* color: #ffffff; */
}

.reusebody .content p span {
  color: #f69314;
}

.reusebody .content p:nth-child(2) {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.02em;
  /* color: rgba(255, 255, 255, 0.9); */
  max-width: 800px;
  margin: auto;
  margin-top: 15px;
}

@media only screen and (max-width: 900px) {
  .reusebody .content br {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .reusebody .content {
    padding: 100px 30px;
    height: 50vh;
  }
  .reusebody .content p {
    font-size: 24px;
  }

  .reusebody .content p:nth-child(2) {
    font-size: 16px;
    margin: 15px auto;
  }
}
