.whyanchor {
  margin-top: 100px;
}

.whyanchor.padding {
  padding: 100px;
  padding-top: 0;
}

.whyanchor .firstset p:nth-child(2) {
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;

  margin-top: 30px;
  margin-bottom: 25px;
}

.whyanchor .firstset p:first-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #f4811e;
}

.whyanchor .firstset .so {
  width: 1200px;
}

.whyanchor .firstset p:nth-child(3) {
  font-weight: normal;

  font-size: 23px;
  line-height: 140%;
  max-width: 1015px;
  margin-bottom: 30px;
}

.whyanchor .firstset br {
  display: none;
}

.whyanchor .firstset img {
  display: none;
}

.whyanchor .secondset {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.whyanchor .secondset .build:first-child {
  max-width: 640px;
  margin-top: 30px;
  margin-right: 73px;
}

.whyanchor .secondset .build img {
  width: 100%;
}

.whyanchor .secondset .icontext {
  display: flex;
}

.whyanchor .secondset .icontext .icon {
  margin-right: 32px;
  min-width: 75px;
  max-width: 75px;
  height: 63px;
}

.whyanchor .secondset .icontext .icon img {
  width: 100%;
}

.whyanchor .secondset .icontext .text {
  font-weight: normal;

  font-size: 16px;
  line-height: 145%;
  width: 382px;
}

.whyanchor .secondset .icontext .text p:first-child {
  font-weight: 700;
  font-size: 23px;
  line-height: 120%;
  margin-bottom: 5px;
}

.whyanchor .secondset .icontext {
  margin-bottom: 50px;
}

.whyanchor .thirdset .three {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}

.whyanchor .thirdset .three .image {
  width: 275px;

  font-weight: normal;

  font-size: 16px;
  line-height: 145%;
}

.whyanchor .thirdset .three .image:nth-child(2) {
  margin: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.whyanchor .thirdset.on {
  display: none;
}

.whyanchor .thirdset .three .image .theimg {
  /* max-width: 163px; */
  height: 131px;
  overflow: hidden;
}

.whyanchor .thirdset .three .image p:nth-child(2) {
  font-weight: 700;
  font-size: 23px;
  line-height: 120%;

  margin: 11px 0;
}

@media only screen and (max-width: 1450px) {
  .whyanchor .secondset .build:first-child {
    max-width: 500px;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1300px) {
  .whyanchor .firstset .so {
    width: 1000px;
  }
}

@media only screen and (max-width: 1250px) {
  .whyanchor .secondset .build:first-child {
    max-width: 500px;
    margin-right: 40px;
  }

  .whyanchor .firstset .so {
    font-size: 42px;
    width: 800px;
  }
}

@media only screen and (max-width: 1050px) {
  .whyanchor .firstset p:nth-child(2) {
    font-size: 42px;
  }

  .whyanchor .secondset .icontext .icon {
    margin-right: 20px;
  }

  /* .whyanchor .secondset .build:first-child {
    display: none;
  } */

  .whyanchor .secondset .build:first-child {
    margin-bottom: 30px;
  }

  .whyanchor .secondset {
    flex-direction: column;
  }

  .whyanchor .thirdset .three {
    display: flex;
    margin-top: 30px;
    /* flex-direction: column; */
    /* align-items: center; */
  }

  .whyanchor .thirdset .three .image {
    /* margin-top: 20px; */
    width: 225px;
  }
}

@media only screen and (max-width: 900px) {
  .whyanchor .firstset .so {
    font-size: initial;
    width: initial;
  }
}

@media only screen and (max-width: 850px) {
  .whyanchor {
    margin-top: 0;
  }
  .whyanchor .firstset p:nth-child(2) {
    font-size: 24px;
    line-height: 140%;
  }

  .whyanchor .firstset {
    margin-bottom: 30px;
  }

  .whyanchor .firstset p:last-child {
    font-size: 18px;
  }

  .whyanchor .firstset p:first-child {
    font-size: 14px;
  }

  .whyanchor .firstset br {
    display: block;
  }

  .whyanchor .firstset img {
    display: block;
    width: 295px;
  }

  .whyanchor .secondset .icontext {
    /* margin-top: 100px; */
  }

  .whyanchor .secondset .icontext .icon {
    width: 80px;
    height: 70px;
    margin-right: 22px;
  }

  .whyanchor .secondset .icontext .text {
    width: 281px;
    font-size: 14px;
  }

  .whyanchor .secondset .icontext .text p:first-child {
    font-weight: 700;
    font-size: 16px;
  }

  .whyanchor .secondset .build:first-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 800px) {
  .whyanchor .thirdset.off {
    display: none;
  }

  .whyanchor .thirdset.on {
    display: block;
  }

  .whyanchor .thirdset .three {
    justify-content: space-evenly;
  }

  .whyanchor .thirdset .three .image:nth-child(2) {
    margin: 0;
    margin-left: 20px;
  }

  .whyanchor.padding {
    padding: 30px;
  }

  .whyanchor .secondset .icontext .icon {
    width: 50px;
    height: 50px;
    min-width: 0;
  }
}

@media only screen and (max-width: 500px) {
  .whyanchor .thirdset .three .image {
    width: 100%;
  }
  .whyanchor .thirdset .three .image .theimg.exc img {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .whyanchor .secondset .icontext .text {
    width: 100%;
  }

  .whyanchor .firstset img {
    width: 100%;
  }

  .whyanchor .thirdset .three .image {
    width: 100%;
  }

  .whyanchor .thirdset .three .image .theimg img {
    max-width: 120px;
  }

  .whyanchor .thirdset .three .image .theimg.exc img {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 340px) {
  .whyanchor .thirdset .three .image .theimg img {
    max-width: 120px;
    width: 100%;
  }
}

@media only screen and (max-width: 270px) {
  .whyanchor .thirdset .three .image:nth-child(2) {
    margin-left: 0;
  }
}
