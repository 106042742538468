.vision {
  background: url("../../images/homepage/building\ 2\ 1\ \(1\).png") no-repeat
    center;
  background-size: cover;
}

.vision .width {
  padding: 60px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vision .width .mission {
  width: 50%;
  min-height: 420px;
  background: #fdfdfe;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 50px 30px;
  text-align: center;
}

.vision .width .mission img {
  width: 100px;
  margin-bottom: 20px;
}

.vision .width .mission:first-child {
  margin-right: 90px;
}

.vision .width .mission p {
  font-weight: bold;
  font-size: 24px;
  /* line-height: 180%; */
}

.vision .width .mission p:nth-child(3) {
  margin: 30px 0 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.how-it-works {
  padding: 200px 100px;
  background: #f8f9fa;
  text-align: center;
  /* margin-top: 180px; */
  /* margin-bottom: 180px; */
}

.how-it-works h2 {
  margin-bottom: 3rem;
  font-size: 32px;
}

.how-it-works .flex > div {
  width: 30%;
}

.how-it-works .flex > div p {
  font-size: 18px;
  line-height: 140%;
}

.how-it-works .flex {
  display: flex;
  justify-content: space-between;
}
.how-it-works h4 {
  color: #343a40;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
}

.how-it-works span.fas,
.how-it-works span.far {
  color: #f69314;
  font-size: 35px;
}

@media only screen and (max-width: 1330px) {
  .body {
    padding: 4rem;
  }

  .vision .width .mission p {
    font-size: 24px;
    /* line-height: 40px; */
  }
}
@media only screen and (max-width: 1000px) {
  .vision .width {
    display: block;
    padding: 45px 25px;
  }
  .vision .width .mission:nth-child(2) {
    margin-top: 20px;
  }

  .vision .width .mission:first-child {
    margin-right: auto;
  }

  .vision .width .mission {
    margin: 0 auto;
    min-height: auto;
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .vision .width .mission {
    width: 100%;

    padding: 30px 20px;
  }

  .vision .width .mission img {
    max-width: 100px;
  }

  .how-it-works {
    padding: 40px 20px;
  }

  .how-it-works .flex {
    flex-direction: column;
  }

  .how-it-works .flex > div {
    width: 100%;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .vision .width .mission img {
    width: 70px;
  }

  .how-it-works {
    margin-top: 0;
    padding-top: 150px;
  }
}
